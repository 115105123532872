'use client'

import { useState, useEffect } from 'react'

import Script from 'next/script'

export default () => {
    const [load, setLoad] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setLoad(true)
        }, 2500)
    }, [])

    if (process.env.NEXT_PUBLIC_ENVIRONMENT != 'prod' || !load) return

    return (
        <Script
            id="ze-snippet"
            src="https://static.zdassets.com/ekr/snippet.js?key=bc700e6d-bbfc-4113-b9c4-ef6d3327c159"
            strategy="afterInteractive"
        ></Script>
    )
}
