/**
 * Utility function to add a value to an object that will be added to potentially undefined children.
 * It will create children as it iterates through the position as child objects until it reaches the
 * end before adding the final key and value pair.
 *
 * @param {Object} object The object to iterate through.
 * @param {String} position Nullable. The position to navigate to.
 * @param {String} reference The key of the final value.
 * @param {*} value The value.
 */
const addToObject = (object, position, reference, value) => {
    // As long as value does not equal null, undefined or is an empty string, proceed.
    try {
        if (value !== null && value !== undefined && value !== '') {
            // If position exists.
            if (position !== null) {
                // Split the position string and iterate through each split.
                position.split('.').forEach((pos) => {
                    // If the child key does not exist, create it as a child object.
                    if (object[pos] === undefined) {
                        object[pos] = {}
                    }
                    // If it does exist, navigate into it.
                    object = object[pos]
                })
            }
            // Once we have reached the end, add the key and value.
            object[reference] = value
        }
    } catch (e) {
        console.log(e)
        return null
    }
}
/**
 * The complete inversion of the addToObject function. This will delete a value from an object.
 *
 * @param {Object} object The object to iterate through.
 * @param {String} position Nullable. The position to navigate to.
 * @param {String} reference The key of the final value.
 */
const deleteFromObject = (object, position, reference) => {
    if (!reference) return
    const originalObject = object
    // As long as value does not equal null, undefined or is an empty string, proceed.
    try {
        const splitArr = position ? position.split('.').filter((item) => item !== '') : []
        // Split the position string and iterate through each split.

        splitArr.forEach((pos) => {
            // If the child key does not exist, create it as a child object.
            if (object[pos] === undefined) {
                object[pos] = {}
            }
            // If it does exist, navigate into it.
            object = object[pos]
        })
        // Once we have reached the end, add the key and value.
        delete object[reference]

        if (Object.keys(object).length === 0 && position !== null) {
            deleteFromObject(originalObject, splitArr.slice(0, -1).join('.'), splitArr[splitArr.length - 1])
        }
    } catch (e) {
        console.log(e)
        return null
    }
}

/**
 * A safe way of retrieving a value and knowing whether the value exists and removing unwanted 'undefined' responses.
 *
 * @param {*} data The data to be processed.
 * @param {String} position Nullable. The string to process to get to the value in question.
 * @param {Boolean} defaultValue Nullable. Returns this value if it's null.
 * @returns
 */
const getValue = (data, position = null, defaultValue = null) => {
    try {
        // If the position string is not null.
        if (position !== null) {
            // Split the position string by the '.' symbols and increment through them.
            position.split('.').forEach((k) => {
                // Try and push the data object forward. Traversing through the object and it's subobjects.
                data = data[k]
            })
        }
        // If the item is a string, trim any extra spaces.
        if (typeof data === 'string') data = data.trim()
        // If the data is not undefined then return it, else return null.
        return data !== undefined ? data : defaultValue
    } catch (e) {
        // If the function fails return null.
        return defaultValue
    }
}

/**
 * Check whether an expected number field is valid and determine the return value.
 *
 * @param {*} data The data to be processed. Could be a number, or a an object.
 * @param {String} position Nullable. The string to process to get to the value in question.
 * @param {Boolean} zero Whether the final value can equal 0.
 * @param {Boolean} float Whether the final value should be returned as a String.
 * @returns Either null, a number or a number string.
 */
const getValueNumber = (data, position = null, zero = false, float = false) => {
    // Determine what the zero value will be.
    let zeroValue = zero ? 0 : null
    zeroValue = float && zero ? '0.00' : zeroValue
    try {
        // If the position string is not null.
        if (position !== null) {
            // Split the position string by the '.' symbols and increment through them.
            position.split('.').forEach((k) => {
                // Try and push the data object forward. Traversing through the object and it's subobjects.
                data = data[k]
            })
        }
        // If the final value is not a number then set it to 0.
        if (Number.isNaN(Number.parseFloat(data))) data = 0
        // If the value is zeroable then return the value as is.

        // If the value needs to be returned as a float.
        if (float) {
            return Number.parseFloat(data) === 0
                ? zeroValue
                : Number.parseFloat(Math.round(data * 100) / 100).toFixed(2)
        }
        return Number.parseFloat(data) === 0 ? zeroValue : Number.parseFloat(data)
    } catch (e) {
        // If the functions fails then return zero if zeroable or return null.
        return zeroValue
    }
}

const formatCurrencyFull = (number, includePound = true, includePence = true) => {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    })
    let figure = formatter.format(number)
    if (!includePound) figure = figure.replace('£', '')
    if (!includePence) figure = figure.replace(/(\.|,)00$/g, '')
    return figure
}

const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
}

const setCookie = (name, value, expiry, checkCookie = true) => {
    if (checkCookie) {
        const consent = JSON.parse(getCookie('CookieConsent'))
        if (!consent || !consent.personalisation) return
    }

    let cookieString = name + '=' + value + ';path=/;'
    if (expiry) {
        var now = new Date()
        var time = now.getTime()
        var expireTime = time + expiry
        now.setTime(expireTime)

        cookieString += 'expires=' + now.toUTCString() + ';'
    }

    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local') {
        cookieString += 'domain=.taxd.co.uk;'
    }

    document.cookie = cookieString
}

const getDomain = (domain = 'dashboard') => {
    switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
        case 'local':
            return 'http://localhost:3000'
        case 'dev':
            return `https://${domain}-dev.taxd.co.uk`
        case 'qa':
            return `https://${domain}-qa.taxd.co.uk`
        case 'prod':
            return `https://${domain}.taxd.co.uk`
    }
}

module.exports = {
    formatCurrencyFull,
    addToObject,
    deleteFromObject,
    getValue,
    getValueNumber,
    getCookie,
    setCookie,
    getDomain,
}
