'use client'

import Script from 'next/script'

export default () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT != 'prod') return

    return (
        <Script id="ruler-tag" strategy="afterInteractive">
            {`var __raconfig = __raconfig || {};
                    __raconfig.uid = "67079b453a657";
                    __raconfig.action = "track";
                    (function () {
                      var ra = document.createElement("script");
                      ra.type = "text/javascript";
                      ra.src = "https://ruler.nyltx.com/lib/1.0/ra-bootstrap.min.js";
                      var s = document.getElementsByTagName("script")[0];
                      s.parentNode.insertBefore(ra, s);
                    }());`}
        </Script>
    )
}
