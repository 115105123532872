'use client'

import React, { useState } from 'react'

import styles from './Toggle.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

export default function Toggle({ toggleName, value = false, callback = null, disabled = false }) {
    // Set the state to the initial choices whilst taking into consideration the current answers.
    const [state, setState] = useState(value)

    /**
     * A function to toggle the clicked option.
     *
     * @param {Element} target The target element
     */
    const toggleSelected = ({ target }) => {
        const answerBoolean = target.checked
        // Update the answer that is being clicked in the state.
        setState(answerBoolean)
        // Call the callback function if it exists.
        if (callback) callback(answerBoolean)
    }

    /* Toggle input that when clicked changes the state */
    return (
        <label className={classes('switch', { disabled })}>
            <input
                type="checkbox"
                defaultChecked={state}
                name={toggleName}
                value={state !== null ? state : false}
                onClick={toggleSelected}
                disabled={disabled}
            />
            <span className={classes('slider', 'round')}></span>
        </label>
    )
}
