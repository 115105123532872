'use client'

import React from 'react'
import { usePathname } from 'next/navigation'

const DynamicHead = () => {
    const pathName = usePathname()
    const canonicalUrl = `https://www.taxd.co.uk${pathName}`
    return <link rel="canonical" href={canonicalUrl} />
}

export default DynamicHead
